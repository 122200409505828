<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" >
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="英文名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="税号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['unique_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="法人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['legal_representative', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="国家" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="false">
          <a-input v-decorator="['country', {initialValue: data.country}, {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="国家" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            v-decorator="['country', {rules: [{ required: false, message: '请选择' }]}]"
            placeholder="选择"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption">
            <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['contact', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="电话" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['phone', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['mail', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="传真" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['fax', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item v-bind="buttonCol">
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { client_update, client_list } from '@/api/trade_client'
// 表单字段
const fields = ['id', 'name', 'en_name', 'unique_code', 'legal_representative', 'country', 'contact', 'phone', 'mail', 'fax']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      data: {}
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
  },
  mounted () {
    client_list({ id: this.$route.params.id })
      .then(res => {
        console.log(res.data, '我是数据列表@')
        const list = res.data.entries
        if (list && list.length > 0) {
          this.data = list[0]
          this.id = this.data.id
          this.loadEditInfo(this.data)
        }
      })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        client_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
